'use client';

import classnames from 'classnames';
import { useLocale } from 'next-intl';

import { Chip, ImageWrapper, ShareDropdownClient } from '@module/mdrt-org/shared/components';
import { ChipTheme, RenderHTML } from '@shared/ui-components';
import { formatTranslationStringOnClient, GTM_HERO_CAROUSEL_HEADER_VALUE } from '@shared/utils';

import { BookmarkButtonClient } from '../../../../../../components/bookmark-button/bookmark-button-client';
import { type BookmarkButtonCmsContent } from '../../../../../../components/bookmark-button/get-bookmark-button-cms-content';
import { type ShareDropdownCmsContent } from '../../../../../../components/share-dropdown/get-share-dropdown-cms-content';
import { DEFAULT_LOCALE } from '../../../../../../i18n/locales';
import { type LearnContentCarouselCardFragment } from '../../../../../../utils/data/graphql/_generated/gql-generated';
import { convertSecondsToMinutes } from '../../../../../../utils/helpers/convert-seconds-to-minutes';
import { getCardLinkDescription } from '../../../../../../utils/helpers/get-card-link-description';
import { getContentFallbackImageSource } from '../../../../../../utils/helpers/get-content-fallback-image-source';
import { getContentTypeIconComponent } from '../../../../../../utils/helpers/get-content-type-icon-component';
import { getFeaturedPeopleOrAuthors } from '../../../../../../utils/helpers/get-featured-people-or-authors';
import { getTextFromHtmlString } from '../../../../../../utils/helpers/get-text-from-html-string';
import { showFeatureInStagingEnvironmentsOnly } from '../../../../../../utils/helpers/show-feature-in-staging-environment-only';

import styles from './learn-hero-carousel-card.module.scss';

export type LearnHeroCarouselCardProps = {
  bookmarkButtonCmsContent: BookmarkButtonCmsContent;
  card: LearnContentCarouselCardFragment;
  isAuthenticated: boolean;
  isBookmarked: boolean;
  minutesLabel: string;
  shareDropdownCmsContent: ShareDropdownCmsContent;
  viewActionLabel: string;
};

export const LearnHeroCarouselCard = ({
  bookmarkButtonCmsContent,
  card,
  isAuthenticated,
  isBookmarked,
  minutesLabel,
  shareDropdownCmsContent,
  viewActionLabel,
}: LearnHeroCarouselCardProps) => {
  const ContentTypeIconComponent = getContentTypeIconComponent(card?.ContentTypeSelection);
  const fallbackImageSource = getContentFallbackImageSource(card?.ContentTypeSelection);
  const imageAlt = card?.ThumbnailImage?.AltText;
  const people = getFeaturedPeopleOrAuthors(card);
  const firstAuthor = people[0];
  const otherAuthorsCount = firstAuthor && people.length > 1 ? people.length - 1 : undefined;
  const description = getCardLinkDescription(card?.SubheadingText, card?.Subheading);
  const locale = useLocale();
  const formattedRelativePath =
    locale === DEFAULT_LOCALE
      ? card?.RelativePath
      : card?.RelativePath?.replace(/^\/\w+\//u, `/${locale}/`);
  const formattedUrl = `${
    process.env.NEXT_PUBLIC_BASE_URL || window.location.origin
  }${formattedRelativePath}`;
  const formattedReadTimeLabel =
    card.ReadTimeInSeconds && card.ReadTimeInSeconds > 0
      ? formatTranslationStringOnClient(minutesLabel, {
          readTime: convertSecondsToMinutes(card.ReadTimeInSeconds),
        })
      : null;

  return (
    <div className={styles['learn-hero-carousel-card']} tabIndex={0}>
      <div className={styles['learn-hero-carousel-card__content']}>
        <div className={styles['learn-hero-carousel-card__info']}>
          <p className={styles['learn-hero-carousel-card__authors']} title={people.join('; ')}>
            <span className={styles['learn-hero-carousel-card__first-author']}>{firstAuthor}</span>
            {otherAuthorsCount && (
              <span className={styles['learn-hero-carousel-card__author-count']}>
                +{otherAuthorsCount}
              </span>
            )}
          </p>
          {formattedReadTimeLabel && (
            <span
              className={classnames(styles['learn-hero-carousel-card__read-time'], {
                [styles['learn-hero-carousel-card__read-time--with-separator']]: firstAuthor,
              })}
            >
              {formattedReadTimeLabel}
            </span>
          )}
        </div>
        {card?.Heading && (
          <h1 className={styles['learn-hero-carousel-card__title']} title={card.Heading}>
            {card.Heading}
          </h1>
        )}
        <RenderHTML
          className={styles['learn-hero-carousel-card__description']}
          html={description}
          title={getTextFromHtmlString(description)}
        />
        <div className={styles['learn-hero-carousel-card__actions']}>
          <Chip
            data-ga-carousel-header={GTM_HERO_CAROUSEL_HEADER_VALUE}
            icon={<ContentTypeIconComponent />}
            label={viewActionLabel}
            theme={ChipTheme.Emphasis}
            to={formattedUrl || '#'}
          />
          <ShareDropdownClient
            cmsContent={shareDropdownCmsContent}
            menuPlacement="bottom left"
            title={card.Heading}
            url={formattedUrl}
          />
          {/* TODO: remove feature flag when feature will be tested and ready */}
          {showFeatureInStagingEnvironmentsOnly() && isAuthenticated && (
            <BookmarkButtonClient
              cmsContent={bookmarkButtonCmsContent}
              contentType={card.ContentTypeSelection}
              isBookmarked={isBookmarked}
              learningMaterialId={card.ContentLink?.GuidValue}
            />
          )}
        </div>
      </div>
      <ImageWrapper
        alt={imageAlt ?? card?.Heading ?? ''}
        className={styles['learn-hero-carousel-card__image']}
        fallbackSrc={fallbackImageSource}
        height={800}
        src={card?.ThumbnailImage?.ImageReference?.Url ?? fallbackImageSource}
        width={800}
      />
    </div>
  );
};
