'use client';

import { BookmarkFilledIcon, BookmarkIcon, Button } from '@shared/ui-components';
import { formatTranslationStringOnClient } from '@shared/utils';

import { deleteBookmarks, postBookmarks } from '../../utils/data';
import { useMutationHandler } from '../../utils/hooks';

import { type BookmarkButtonCmsContent } from './get-bookmark-button-cms-content';
import { getContentCategory } from './get-content-category-label';

type BookmarkButtonClientProps = {
  cmsContent: BookmarkButtonCmsContent;
  contentType: number | null | undefined;
  isBookmarked: boolean;
  learningMaterialId: string | null | undefined;
};

export const BookmarkButtonClient = ({
  cmsContent,
  contentType,
  isBookmarked,
  learningMaterialId,
}: BookmarkButtonClientProps) => {
  const contentTypeLabel = getContentCategory({
    contentType,
    labels: cmsContent.contentCategoryLabels,
  });
  const { isMutationPending: isAddBookmarkPending, mutate: addBookmark } = useMutationHandler({
    mutationFunction: postBookmarks,
    scrollToTop: false,
    successMessage: formatTranslationStringOnClient(cmsContent.addBookmarkSuccessMessage, {
      contentType: contentTypeLabel,
    }),
  });
  const { isMutationPending: isDeletePending, mutate: deleteBookmark } = useMutationHandler({
    mutationFunction: deleteBookmarks,
    scrollToTop: false,
    successMessage: formatTranslationStringOnClient(cmsContent.removeBookmarkSuccessMessage, {
      contentType: contentTypeLabel,
    }),
  });

  const handleBookmarkToggle = () => {
    if (isBookmarked) {
      deleteBookmark({ bookmarkId: learningMaterialId });
    } else {
      addBookmark({ learningMaterialId });
    }
  };

  return (
    <Button
      Icon={isBookmarked ? BookmarkFilledIcon : BookmarkIcon}
      aria-label={
        isBookmarked ? cmsContent.removeBookmarkAriaLabel : cmsContent.addBookmarkAriaLabel
      }
      aria-pressed={isBookmarked}
      disabled={isAddBookmarkPending || isDeletePending}
      onClick={handleBookmarkToggle}
    />
  );
};
