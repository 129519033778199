import { ContentType } from '../../utils/data/constants';

type Labels = {
  article: string;
  audio: string;
  guide: string;
  video: string;
};

export const getContentCategory = ({
  contentType,
  labels,
}: {
  contentType: ContentType | number | null | undefined;
  labels: Labels;
}) => {
  const contentTypeToLabel: { [key: number]: keyof Labels } = {
    [ContentType.Article]: 'article',
    [ContentType.Video]: 'video',
    [ContentType.Audio]: 'audio',
    [ContentType.Podcast]: 'audio',
    [ContentType.Guide]: 'guide',
  };
  const labelKey = contentTypeToLabel[contentType ?? -1];

  return labelKey ? labels[labelKey] : '';
};
